import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

@NgModule({
	imports: [
		RouterModule.forRoot(
			[
				{
					path: "",
					loadChildren: () => import('./main/main.module').then(m => m.MainModule),
					data: {
						reuse: "main"
					}
				}
				// { path: "", loadChildren: "./intro/intro.module#IntroModule" }
			],
			{
				initialNavigation: "enabled"
			}
		)
	],
	providers: [],
	declarations: [],
	exports: [RouterModule]
})
export class AppRoutingModule {}
